import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import absmartly from '@absmartly/javascript-sdk'
import stringHash from '@sindresorhus/string-hash'
import Sentry from '@/modules/Sentry'
import lscache from 'lscache'
import uuid from 'uuid-random'
import parseISO from 'date-fns/parseISO'
import getUnixTime from 'date-fns/getUnixTime'

import config from '@/config'
import Jet from '@/modules/Jet'
import OpenTelemetry from '@/modules/OpenTelemetry'
import { os } from '@/helpers/device.js'
import { ENV_DEV_OR_STAGING, ENV_HACKERONE, VUE_APP_VERSION } from '@/helpers/environment'
import { DOMAINS_WITH_EXPERIMENTS } from '@/funnels/funnels.ts'

const absmartlySDK = new absmartly.SDK({
	endpoint: `${config('AbSmartlyApiHost')}/v1`,
	apiKey: config('AbSmartlyApiKey'),
	environment: ENV_DEV_OR_STAGING || ENV_HACKERONE ? 'Development' : 'Production',
	application: 'Web',
	timeout: 7000,
})

const trackedExperiments = {}
const getVariantLetterByNumber = (number) => String.fromCharCode(65 + number)

/**
 * Generates an uuid and caches it in `localStorage` forever
 * It is necessary for using unit `deviceId` in the A/B Smartly
 */
export function setDeviceId() {
	let deviceId = lscache.get('deviceId')

	if (!deviceId) {
		deviceId = uuid()
		lscache.set('deviceId', deviceId)
	}

	window.deviceId = deviceId
}

/**
 * Hash function that converts uuid string to a number from interval
 * @param {string} id - uuid string
 * @returns {number} - number [0..9]
 */
const calcBucket = function (id) {
	if (typeof id !== 'string') {
		return -1
	}

	const hash = stringHash(id)

	return hash % 10
}

/**
 * Function that transforms url param `exp` (i.e.`?exp=web_aa_test1_onboarding~1&exp=web_aa_test2_cancellation~1`) to an object
 * @param {string|string[]} queryParamsExp - value of the `exp` param from store - string or array for multiple
 * @returns {object} - result object, i.e. `{ web_aa_test1_onboarding: 1, web_aa_test2_cancellation: 1 }`
 */
const getOverrideObjectFromUrlParams = function (queryParamsExp) {
	if (typeof queryParamsExp === 'string') {
		queryParamsExp = [queryParamsExp]
	}

	return queryParamsExp.reduce((result, queryParamsString) => {
		const [name, variant] = queryParamsString.split('~')
		const variantNumber = parseInt(variant)

		if (isNaN(variantNumber)) {
			return result
		}

		return { ...result, [name]: variantNumber }
	}, {})
}

export default {
	watch: {
		networkResourceIsReady(value) {
			if (value) {
				this.initAbSmartly()
			}
		},
	},

	computed: {
		...mapState({
			user: 'user',
			subscription: 'subscription',
		}),
		...mapGetters({
			abSmartlyExposures: 'getAbSmartlyExposures',
			appAreaConfig: 'location/getAppAreaConfig',
			getQueryParam: 'location/getQueryParam',
			hostname: 'location/hostname',
			networkResourceIsReady: 'status/networkResourceIsReady',
			isOnboarded: 'isOnboarded',
			countryCode: 'getCountryCode',
			isOrganicTrafficOnboarding: 'userFlags/getIsOrganicTrafficOnboarding',
			initialPath: 'location/getInitialPath',
		}),
		isExperimentsDisabled() {
			const isExperimentsDisabledByQueryParam = Boolean(this.getQueryParam('safe'))
			const isExperimentsEnabledByQueryParam = Boolean(this.getQueryParam('unsafe'))
			const utmCampaign = this.getQueryParam('utm_campaign')
			const isProductionNonExpDomain = !DOMAINS_WITH_EXPERIMENTS.includes(this.hostname)

			if (isExperimentsEnabledByQueryParam) {
				return false
			}

			return (
				isExperimentsDisabledByQueryParam ||
				this.isOrganicTrafficOnboarding ||
				isProductionNonExpDomain ||
				utmCampaign?.toString().includes('Discovery')
			)
		},
	},

	methods: {
		...mapMutations({
			addAbSmartlyExposure: 'addAbSmartlyExposure',
			removeAbSmartlyExposure: 'removeAbSmartlyExposure',
		}),

		...mapActions({
			sendAbSmartlyExposuresToBraze: 'sendAbSmartlyExposuresToBraze',
		}),

		setAbSmartlyAttributes() {
			const utmSource = this.getQueryParam('utm_source')
			const utmCampaign = this.getQueryParam('utm_campaign')
			const utmGender = this.getQueryParam('utm_gender')
			const hostname = ENV_DEV_OR_STAGING ? this.getQueryParam('host') ?? window.location.hostname : this.hostname
			const attributes = {
				device: os,
				device_bucket: calcBucket(window.deviceId),
				deviceId: window.deviceId,
				user_language: window.language,
				app_version: VUE_APP_VERSION,
				hostname: hostname,
				app_area: this.appAreaConfig.appArea,
			}

			if (utmSource) {
				attributes.utm_source = utmSource
			}

			if (utmCampaign) {
				attributes.utm_campaign = utmCampaign
			}

			if (utmGender) {
				attributes.utm_gender = utmGender
			}

			this.abSmartlyContext.attributes(attributes)

			this.setAbSmartlyUserAttributes(this.user)
			this.setAbSmartlySubscriptionAttributes(this.subscription)
			this.setAbSmartlyCountryAttributes(this.countryCode)
		},

		setAbSmartlyUserAttributes(user) {
			if (!user) {
				return
			}

			this.abSmartlyContext.attributes({
				user_bucket: calcBucket(user.id),
				user_created_timestamp: getUnixTime(parseISO(user.createdAt)),
			})
		},

		setAbSmartlySubscriptionAttributes(subscription) {
			if (!subscription) {
				return
			}

			this.abSmartlyContext.attributes({
				has_subscription: subscription.period_type === 'normal',
				has_trial: subscription.period_type === 'trial',
			})
		},

		setAbSmartlyCountryAttributes(countryCode) {
			if (!countryCode) {
				return
			}

			this.abSmartlyContext.attributes({
				country: countryCode.toUpperCase(),
			})
		},

		setAbSmartlyAnalyticsUserProperty() {
			const exposuresList = this.abSmartlyExposures.reduce((list, exposure) => {
				const { name, iteration, variantLetter } = exposure
				const underscoredName = name.replaceAll(' ', '_')

				list.push(`${underscoredName}(${variantLetter})(${`i${iteration}`})`)

				return list
			}, [])

			this.$analytic.setUserProperties({ active_test_groups: exposuresList })
		},

		overrideAbSmartlyTreatmentVariants() {
			const queryParamsExp = this.getQueryParam('exp')

			if (this.isExperimentsDisabled) {
				const experiments = this.getAbSmartlyContextExperiments()

				if (!Array.isArray(experiments)) {
					return
				}

				const overrideObject = experiments.reduce((result, experimentId) => ({ ...result, [experimentId]: 0 }), {})

				this.abSmartlyContext.overrides(overrideObject)
			}

			if (queryParamsExp) {
				this.abSmartlyContext.overrides(getOverrideObjectFromUrlParams(queryParamsExp))
			}
		},

		trackExperimentEnd() {
			const { experiments = [] } = this.abSmartlyContext.data()
			const exposures = this.abSmartlyExposures

			for (const exposure of exposures) {
				const experimentData = experiments.find((experiment) => {
					return experiment.name === exposure.name && experiment.iteration === exposure.iteration
				})

				if (!experimentData) {
					this.$analytic.logEvent('Experiment - End', {
						Name: exposure.name,
						Iteration: `i${exposure.iteration}`,
						Variant: exposure.variantLetter,
						VariantName: exposure.variantName,
					})

					this.removeAbSmartlyExposure({ name: exposure.name, iteration: exposure.iteration })
				}
			}
		},

		sendAbSmartlyExposureToAnalyticsAndBraze(exposure) {
			const { name, iteration, variantLetter, variantName } = exposure

			this.$analytic.logEvent('Experiment - Exposure', {
				Name: name,
				Iteration: `i${iteration}`,
				Variant: variantLetter,
				VariantName: variantName,
			})

			Jet.logLogicalEvent('ExperimentExposure', {
				name,
				iteration: `i${iteration}`,
				variant: variantLetter,
			})

			if (this.isOnboarded) {
				this.sendAbSmartlyExposuresToBraze({
					[`${name}_${iteration}`]: exposure,
				})
			}
		},

		getAbSmartlyContextExperiments() {
			try {
				return this.abSmartlyContext.experiments()
			} catch (e) {
				return []
			}
		},

		abSmartlyReady() {
			Vue.prototype.$absmartly = this.abSmartlyContext
			Vue.prototype.$absmartly.assign = (experimentId) => {
				const queryParamsExp = this.getQueryParam('exp')
				const overrideObject = queryParamsExp ? getOverrideObjectFromUrlParams(queryParamsExp) : {}
				const isExperimentOverridden = Boolean(experimentId in overrideObject)

				const assignment = this.abSmartlyContext._assign(experimentId)
				if (!assignment.assigned && isExperimentOverridden) {
					return true
				}

				return assignment.assigned
			}

			// Proxy for the `treatment` method to return 0 if the experiment is not assigned
			// This reduces the number of exposures on the A/B Smartly side
			Vue.prototype.$absmartly.treatment = new Proxy(this.abSmartlyContext.treatment, {
				apply(method, thisObject, argsList) {
					const experimentName = argsList[0]
					const assignment = thisObject._assign(experimentName)

					if (!assignment.assigned) {
						if (!assignment.overridden) {
							return 0
						} else {
							if (ENV_DEV_OR_STAGING && !(experimentName in trackedExperiments)) {
								trackedExperiments[experimentName] = assignment.variant
								/* eslint-disable no-console */
								console.groupCollapsed(
									`%cA/B Smartly [debug]: %cexposure — ${experimentName} — ${getVariantLetterByNumber(
										assignment.variant,
									)}`,
									'font-weight: 400',
									assignment.variant ? 'color: #669933' : 'font-weight: 400',
								)
								console.log({
									experimentName,
									variant: getVariantLetterByNumber(assignment.variant),
									assignment,
								})
								console.groupEnd()
								/* eslint-enable no-console */
							}
							return assignment.variant
						}
					}

					return method.apply(thisObject, argsList)
				},
			})

			this.overrideAbSmartlyTreatmentVariants()
			this.trackExperimentEnd()

			this.$store.commit('status/setResourceReadiness', 'absmartly')
		},

		abSmartlyExposure(data) {
			const { assigned, eligible, fullOn, name, variant } = data

			if (!assigned || !eligible || fullOn) {
				return
			}

			const { experiments } = this.abSmartlyContext.data()
			const variantLetter = getVariantLetterByNumber(variant)
			const experimentData = experiments.find((experiment) => experiment.name === name)

			if (!experimentData) {
				this.abSmartlyError(new Error('Exposured experiment is not in the list of active experiments'))
				return
			}

			const variantName = experimentData.variants[variant]?.name
			const iteration = experimentData.iteration

			this.addAbSmartlyExposure({ name, iteration, variantLetter, variantName })
		},

		abSmartlyError(error) {
			const abSmartlyWarning = ['TimeoutError', 'RetryError'].includes(error.name)

			if (abSmartlyWarning) {
				return
			}

			Sentry.withScope((scope) => {
				scope.setExtras({
					attributes: this.abSmartlyContext?.getAttributes(),
					units: this.abSmartlyContext?.getUnits(),
					experiments: this.getAbSmartlyContextExperiments(),
					exposuresLocalStorage: this.abSmartlyExposures,
				})
				scope.setTags({
					abSmartlyError: 'error',
				})
				scope.setTransactionName('A/B Smartly error')
				scope.captureException(error)
			})
		},

		abSmartlyEventLogger(context, eventName, data) {
			if (eventName === 'error') {
				this.abSmartlyError(data)
			}

			if (eventName === 'exposure') {
				this.abSmartlyExposure(data)
			}

			if (ENV_DEV_OR_STAGING) {
				/* eslint-disable no-console */
				if (eventName === 'exposure') {
					console.groupCollapsed(
						`%cA/B Smartly [debug]: %cexposure — ${data.name} — ${getVariantLetterByNumber(data.variant)}`,
						'font-weight: 400',
						eventName === 'exposure' ? 'color: #669933' : 'font-weight: 400',
					)
				} else {
					console.groupCollapsed(`%cA/B Smartly [debug]: ${eventName}`, 'font-weight: 400')
				}
				console.log(context)
				console.log(data)
				console.groupEnd()
				/* eslint-enable no-console */
			}
		},

		initAbSmartly() {
			absmartlySDK.setEventLogger(this.abSmartlyEventLogger)

			OpenTelemetry.startSpan('absmartly-init', {
				attributes: { 'span.type': 'absmartly-init' },
			})

			this.abSmartlyContext = absmartlySDK.createContext({
				units: { deviceId: window.deviceId },
			})

			this.setAbSmartlyAttributes()

			this.abSmartlyContext
				.ready()
				.then(this.abSmartlyReady)
				.catch(this.abSmartlyError)
				.finally(() => {
					OpenTelemetry.endSpan('absmartly-init')
				})
		},

		setAbSmartlyAppAreaAttribute(appArea) {
			if (appArea) {
				this.abSmartlyContext.attributes({
					app_area: appArea,
				})
			}
		},
	},

	created() {
		this.storeObserver = this.$store.subscribe((mutation) => {
			if (!this.abSmartlyContext) {
				return
			}
			if (['addAbSmartlyExposure', 'removeAbSmartlyExposure'].includes(mutation.type)) {
				this.setAbSmartlyAnalyticsUserProperty()

				if (mutation.type === 'addAbSmartlyExposure') {
					this.sendAbSmartlyExposureToAnalyticsAndBraze(mutation.payload)
				}
			} else if (mutation.type === 'setUser') {
				this.setAbSmartlyUserAttributes(mutation.payload)
			} else if (mutation.type === 'setSubscription') {
				this.setAbSmartlySubscriptionAttributes(mutation.payload)
			} else if (mutation.type === 'setGeo') {
				this.setAbSmartlyCountryAttributes(mutation.payload?.countryCode)
			} else if (mutation.type === 'location/setAppAreaConfig') {
				const { appArea } = mutation.payload

				this.setAbSmartlyAppAreaAttribute(appArea)
			}
		})
	},

	beforeDestroy() {
		this.storeObserver?.()
	},
}
