import { i18n } from '@/i18n'
import api from '@/api'
import {
	ANALYTIC_AGE_TO_AGE_RANGE_MAP,
	VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY,
	REQUIRED_ANALYTIC_PROPERTIES,
	REQUIRED_ANALYTIC_PROPERTIES_VALUES_MAP,
} from '@/helpers/constants'
import { round } from '@/helpers/utils'
import { convertHeight, convertWeights } from '@/helpers/userData'
import Sentry from '@/modules/Sentry'

export default {
	namespaced: true,

	state: {
		values: {
			height: null,
			weight: null,
			birthDate: null,
			targetWeight: null,
			secondsFromGMT: null,
			sex: '', //male
			intermittent_fasting: [],
			isEmailSubscriptionDisabled: null,
			consents: [],

			data: {
				age: null,
				goal: ['lose_weight'],
				gender: [],
				strategy: [],
				lunch: [],
				feelings: [],
				hopings: [],
				happy_weight_1: [],
				happy_weight_2: [],
				meal_shedule: [],
				diet: [],
				meals: [],
				work: null,
				sport: ['not_at_all'],
				sleep: [],
				health_conditions: [],
				liveProblem: [],
				drugs: [],
				bodyType: null,
				carbs: [],
				water: null,
				measurement_system: null,
				measurement_system_height: null,
				measurement_system_weight: null,
				area: [],
				rq_1: [],
				rq_2: [],
				rq_3: [],
				rq_4: [],
				adt: [],
				motivation_event: [],
				motivation_event_custom: '',
				special_event: '',
				planUp: false,
				planDown: false,
				following_statement_1: null,
				following_statement_2: null,
				following_statement_3: null,
				following_statement_4: null,
				following_statement_5: null,
				following_statement_6: null,
				priorites: [],
				chronotype: [],
				cancel_reason: [],
				second_goal: [],

				at_happy_weight: [],
				confidence_in_reaching: [],
				current_priorities: [],
				eating_habits: [],
				gain_weight_reason: [],
				last_happy_weight: [],
				like_to_boost: [],
				feel: [],
				main_reason: [],
				mental_health_services: [],
				explore: [],
				motivation_to_lose_weight: [],
				see_my_self: [],
				snacking_triggers: [],
				typical_day: [],
				typical_meal: [],
				understanding_mindset: [],
				weight_loss_apps: [],
				weight_loss_attempts: [],
				weight_loss_goal: [],
				weight_loss_goals: [],
				what_make_uncertain: [],
				your_plan_ready: [],
				eating_disorder: [],
				pregnancy: [],
				gender_identity: null,
				non_binary_identity: null,
			},
		},
		model: {},
		assessment: {},
		analyticData: {
			userStepCount: 1,
			onboardingStepCount: 1,
			requiredUserData: {},
		},
	},

	getters: {
		getPropertyValue:
			(state) =>
			(property, isData = false, legacyAnalytics = false) => {
				let values = isData ? state.values.data[property] : state.values[property]
				if (legacyAnalytics && property in VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY) {
					try {
						// Replace new values with old values for legacy analytics events DEV-2000
						if (Array.isArray(values)) {
							values = values.map((v) => {
								return VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY[property][v] ?? v
							})
						} else {
							values = VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY[property][values] ?? values
						}
					} catch (e) {
						Sentry.captureException(e)
					}
				}
				return values
			},

		userWeightInKg: (state) => {
			return state.values.weight
		},

		userTargetWeightInKg: (state) => {
			return state.values.targetWeight
		},

		userWeightByUnitSystem: (state, getters, rootState, rootGetters) =>
			convertWeights(state.values.weight, rootGetters.getWeightUnitSystem),

		userTargetWeightByUnitSystem: (state, getters, rootState, rootGetters) =>
			convertWeights(state.values.targetWeight, rootGetters.getWeightUnitSystem),

		userHeightByUnitSystem: (state, getters, rootState, rootGetters) =>
			convertHeight(state.values.height, rootGetters.getHeightUnitSystem),

		userHeightInCm: (state) => state.values.height,

		healthNoticeHidden(state, getters) {
			let diseases = getters.getPropertyValue('health_conditions', true)
			let drugs = getters.getPropertyValue('drugs', true)
			return drugs.includes('no') && diseases.includes('none')
		},

		userSex(state, getters) {
			return getters.getPropertyValue('sex', false) || 'female'
		},

		userAge(state, getters) {
			return getters.getPropertyValue('age', true)
		},

		ageRange(state, getters) {
			return ANALYTIC_AGE_TO_AGE_RANGE_MAP[getters.userAge] ?? 'other'
		},

		getUserHasConsent(state) {
			return (consent) => {
				try {
					return state.values.consents.includes(consent)
				} catch (e) {
					return false
				}
			}
		},

		getAssesment(state) {
			return state.assessment
		},

		getUserStepCount(state) {
			return state.analyticData.userStepCount
		},

		getOnboardingStepCount(state) {
			return state.analyticData.onboardingStepCount
		},

		brazeAttributes(state, getters, rootState, rootGetters) {
			let gender = 'u'
			let emailSubscribe = null

			if (getters.userSex === 'female') {
				gender = 'f'
			} else if (getters.userSex === 'male') {
				gender = 'm'
			}

			if (getters.getPropertyValue('isEmailSubscriptionDisabled', false)) {
				emailSubscribe = 'unsubscribed'
			}

			return {
				external_id: rootGetters.getUserId,
				email: rootGetters.getUserEmail,
				gender: gender,
				country: rootGetters.getCountryCodeUppercase,
				language: window.language.substr(0, 2),
				email_subscribe: emailSubscribe,
				custom_attrs: {
					funnel: rootGetters['location/getAppAreaConfig'].funnel.slug,
					currency: rootGetters.getCurrency,
					height: getters.userHeightInCm ? round(getters.userHeightInCm, 2) : null,
					weight: getters.userWeightInKg ? round(getters.userWeightInKg, 2) : null,
					original_weight: getters.userWeightInKg ? round(getters.userWeightInKg, 2) : null,
					target_weight: getters.userTargetWeightInKg ? round(getters.userTargetWeightInKg, 2) : null,
				},
			}
		},
	},

	mutations: {
		updateSurveyModel(state, data) {
			state.model = data
		},

		updateAssessment(state, data) {
			state.assessment = data
		},

		updateProperty(state, data) {
			if (data.isData) {
				this._vm.$set(state.values.data, data.property, data.value)
			} else {
				this._vm.$set(state.values, data.property, data.value)
			}
			state.changed = true
		},

		addUserConsent(state, consent) {
			const consents = state.values.consents
			if (!consents.includes(consent)) {
				consents.push(consent)
			}
		},
		removeUserConsent(state, consent) {
			const consents = state.values.consents
			const index = consents.indexOf(consent)
			if (index !== -1) {
				consents.splice(index, 1)
			}
		},

		incrementUserStep(state) {
			state.analyticData.userStepCount += 1
		},

		setOnboardingStepCount(state, count) {
			state.analyticData.onboardingStepCount = count
		},

		resetAnalyticDataCounter(state) {
			state.analyticData.userStepCount = 1
			state.analyticData.onboardingStepCount = 1
		},

		setRequiredUserData(state, data) {
			state.analyticData.requiredUserData = {
				...state.analyticData.requiredUserData,
				...data,
			}
		},
	},

	actions: {
		async saveSurveyData(store, params = {}) {
			const onboardingData = {}
			const metaData = {}

			// Every value in onboardingData should be an array of strings or null
			Object.entries(store.state.values.data).forEach(([key, value]) => {
				if (Array.isArray(value) && value.length) {
					onboardingData[key] = value
				} else if (value) {
					onboardingData[key] = [String(value)]
				} else {
					onboardingData[key] = null
				}
			})

			// metaData can have any type of json-safe values
			Object.entries(store.state.values).forEach(([key, value]) => {
				if (key !== 'data' || key !== 'helpers') {
					metaData[key] = value
				}
			})

			let time_offset = -new Date().getTimezoneOffset() * 60
			let response = await api.updateUser({
				...store.state.values,
				locale: i18n.locale,
				secondsFromGMT: time_offset,
				platformFirst: 'web',
				funnel: store.rootGetters['location/getAppAreaConfig'].appArea,
				...params,
				onboardingData,
				metaData,
			})

			return response.data
		},

		async saveFunnelTags(store) {
			const { options } = store.rootGetters['location/getAppAreaConfig']

			if (!Array.isArray(options?.userTags) || store.rootState.subscription) {
				return
			}

			await api.saveFunnelTags({
				tags: options.userTags,
			})

			if (store.rootGetters.hasAlreadyExistedAccount) {
				api.mergeUserWithAlreadyExisted()
			}
		},

		async updateSurveyData(store, data) {
			let time_offset = -new Date().getTimezoneOffset() * 60

			let response = await api.updateUser({
				...store.state.values,
				name: data.name,
				email: data.email,
				secondsFromGMT: time_offset,
			})

			return response.data
		},

		handleUserAnalyticData(store, data) {
			const isDataHasValues = data && Object.keys(data).length
			if (!isDataHasValues) {
				return
			}

			const validateRequiredDataProperty = (key) => {
				try {
					const isPropertyValueVaild = REQUIRED_ANALYTIC_PROPERTIES_VALUES_MAP[key].includes(data[key])

					if (!isPropertyValueVaild) {
						throw new Error(`Analytic Events Validation: Invalid value`, {
							cause: {
								appArea: store.rootGetters['location/getAppAreaConfig'].appArea,
								funnelId: store.rootGetters['location/getAppAreaConfig'].funnel.id,
								wrongData: {
									[key]: data[key],
								},
							},
						})
					}
				} catch (error) {
					Sentry.withScope((scope) => {
						scope.setTags({
							requiredUserProperties: true,
						})
						scope.captureException(error)
					})
				}
			}

			for (const key in data) {
				if (REQUIRED_ANALYTIC_PROPERTIES.includes(key)) {
					if (REQUIRED_ANALYTIC_PROPERTIES_VALUES_MAP[key]) {
						validateRequiredDataProperty(key)
					}

					store.commit('setRequiredUserData', { [key]: data[key] })
				}
			}
		},

		validateUserAnalyticData(store) {
			const requiredData = store.state.analyticData.requiredUserData
			const missingData = []

			try {
				for (const key of REQUIRED_ANALYTIC_PROPERTIES) {
					if (!requiredData[key]) {
						missingData.push(key)
					}
				}

				if (missingData.length) {
					throw new Error('Analytic Events Validation: Required user properties missed', {
						cause: {
							email: store.rootGetters['getUserEmail'],
							appArea: store.rootGetters['location/getAppAreaConfig'].appArea,
							funnelId: store.rootGetters['location/getAppAreaConfig'].funnel.id,
							missingData,
						},
					})
				}
			} catch (error) {
				Sentry.withScope((scope) => {
					scope.setTags({
						requiredUserProperties: true,
					})
					scope.captureException(error)
				})
			}
		},
	},
}
