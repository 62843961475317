import { mapGetters } from 'vuex'
import { FUNNELS } from '@/data/funnels'

export default {
	computed: {
		...mapGetters({
			appAreaConfig: 'location/getAppAreaConfig',
			appTheme: 'getAppTheme',
		}),
		IS_DARK_THEME() {
			return ['dark', 'navy'].includes(this.appTheme)
		},
		MAIN_FUNNEL_ROUTE_NAME() {
			return 'survey'
		},

		AREA() {
			const { appArea } = this.appAreaConfig

			const BAU_MAIN = ['main', 'aff-main', 'wildcard-main'].includes(appArea)
			const BAU_FEMALE = ['female', 'aff-female', 'wildcard-female'].includes(appArea)
			const BAU_MALE = ['male', 'aff-male', 'wildcard-male'].includes(appArea)
			const BAU = [BAU_MAIN, BAU_FEMALE, BAU_MALE].some(Boolean)

			const ACTIVITY_MAIN = ['main-activity', 'aff-main-activity'].includes(appArea)
			const ACTIVITY_FEMALE = ['activity-female-v2', 'aff-activity-female'].includes(appArea)
			const ACTIVITY_MALE = ['activity-male', 'aff-activity-male'].includes(appArea)
			const ACTIVITY = [ACTIVITY_MAIN, ACTIVITY_FEMALE, ACTIVITY_MALE].some(Boolean)

			const YOGA_MAIN = ['main-yoga', 'aff-main-yoga'].includes(appArea)
			const YOGA_FEMALE = ['activity-female-yoga', 'aff-activity-female-yoga'].includes(appArea)
			const YOGA_MALE = ['activity-male-yoga', 'aff-activity-male-yoga'].includes(appArea)
			const YOGA = [YOGA_MAIN, YOGA_FEMALE, YOGA_MALE].some(Boolean)

			const WALKING_MAIN = ['main-walking', 'aff-main-walking'].includes(appArea)
			const WALKING_FEMALE = ['activity-female-walking', 'aff-activity-female-walking'].includes(appArea)
			const WALKING_MALE = ['activity-male-walking', 'aff-activity-male-walking'].includes(appArea)
			const WALKING = [WALKING_MAIN, WALKING_FEMALE, WALKING_MALE].some(Boolean)

			const PILATES_FEMALE = ['activity-female-pilates', 'aff-activity-female-pilates'].includes(appArea)
			const SOMATIC_FEMALE = ['activity-female-somatic-yoga', 'aff-activity-female-somatic-yoga'].includes(appArea)

			return {
				BAU,
				BAU_MAIN,
				BAU_FEMALE,
				BAU_MALE,

				ACTIVITY,
				ACTIVITY_MAIN,
				ACTIVITY_FEMALE,
				ACTIVITY_MALE,

				YOGA,
				YOGA_MAIN,
				YOGA_FEMALE,
				YOGA_MALE,

				WALKING,
				WALKING_MAIN,
				WALKING_FEMALE,
				WALKING_MALE,

				PILATES_FEMALE,
				SOMATIC_FEMALE,
			}
		},

		IS_MAIN_FUNNEL() {
			return [
				FUNNELS.MAIN_UNIVERSAL_FUNNEL.id,
				FUNNELS.MAIN_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.MAIN_BACK_TO_FASTING.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_EMAIL_BEFORE_PREDICT_FUNNEL() {
			if (this.IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP) {
				return true
			}
			return [
				FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
				FUNNELS.MALE_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.FEMALE_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.MALE_BACK_TO_FASTING.id,
				FUNNELS.FEMALE_BACK_TO_FASTING.id,
				FUNNELS.FEMALE_FREE_DIETITIAN_VIDEO_CONSULTATION.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_MALE_FUNNEL() {
			return [
				FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.MALE_BACK_TO_FASTING.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_MALE_OB_FUNNEL() {
			return [FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id, FUNNELS.MALE_FASTING_WELCOME_LIKE_ACTIVITY.id].includes(
				this.appAreaConfig.funnel.id,
			)
		},
		IS_FEMALE_FUNNEL() {
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGAGO_FUNNEL.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EMOTIONAL_NARRATIVE.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EXERCISES_EXAMPLES.id,
				FUNNELS.FEMALE_FUNNEL_SOCIAL_PROOF_FIRST.id,
				FUNNELS.FEMALE_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_SOCIAL_PROOF_FIRST.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_SOCIAL_PROOF_FIRST.id,
				FUNNELS.FEMALE_BACK_TO_FASTING.id,
				FUNNELS.FEMALE_FREE_DIETITIAN_VIDEO_CONSULTATION.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EMOTIONAL_NARRATIVE.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_FUNNEL_SOCIAL_PROOF_FIRST.id,
				FUNNELS.FEMALE_FASTING_WELCOME_LIKE_ACTIVITY.id,
				FUNNELS.FEMALE_FREE_DIETITIAN_VIDEO_CONSULTATION.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_MALE_ACTIVITY_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_SOCIAL_PROOF_FIRST.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL_CRO_V1() {
			return FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id === this.appAreaConfig.funnel.id
		},
		IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_SOCIAL_PROOF_FIRST.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EXERCISES_EXAMPLES.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGAGO_FUNNEL.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_MALE_ACTIVITY_WALKING_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id].includes(this.appAreaConfig.funnel.id)
		},
		IS_MALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_ACTIVITY_ONBOARDING_BUILDER_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGAGO_FUNNEL.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EXERCISES_EXAMPLES.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_FUNNEL_EMAIL_BEFORE_PREDICT() {
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_FREE_DIETITIAN_VIDEO_CONSULTATION.id,
			].includes(this.appAreaConfig.funnel.id)
		},
		IS_FEMALE_50_PLUS_FUNNEL() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FREE_DIETITIAN_VIDEO_CONSULTATION.id,
			].includes(this.appAreaConfig.funnel.id)
		},
	},
}
