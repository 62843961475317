import LandingSwap from '@/components/LandingSwap.vue'

// eslint-disable-next-line import/no-unresolved
import bau from '@/funnels/domains/simple-life-app.com'
// eslint-disable-next-line import/no-unresolved
import activity from '@/funnels/domains/activity.simple-life-app.com'

function generateRoutesFromAppArea(appAreaConfig) {
	const routes = []

	if (appAreaConfig.landing?.route) {
		routes.push({
			path: appAreaConfig.landing.route.path,
			name: appAreaConfig.landing.route.name,
			component: LandingSwap,
			meta: {
				isLanding: true,
			},
		})
	}

	if (appAreaConfig.funnel?.route) {
		routes.push({
			path: appAreaConfig.funnel.route.path,
			name: appAreaConfig.funnel.route.name,
			component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
		})
	}

	return routes
}

export function funnelRoutes() {
	const allRoutes = []

	Object.values([bau, activity]).forEach((domainConfig) => {
		Object.values(domainConfig).forEach((appAreaConfig) => {
			const routes = generateRoutesFromAppArea(appAreaConfig)
			allRoutes.push(...routes)
		})
	})

	return allRoutes
}
