import { Analytics } from '@frontend/simple-growth-frontend-tools'

export default {
	install: (Vue, { options, router, store }) => {
		const analytic = new Analytics(options)

		router.beforeEach((to, from, next) => {
			const hasAnalyticData = Boolean(to.meta.pageAnalyticUxEvent)

			if (hasAnalyticData) {
				const { name, data } = to.meta.pageAnalyticUxEvent

				analytic.logEvent(name, data)
			}

			next()
		})

		Vue.prototype.$analytic = {
			logEvent: (eventName, eventProperties) => {
				const { funnel } = store.getters['location/getAppAreaConfig']
				const initialFunnelType = store.getters['location/getInitialFunnelType']

				return analytic.logEvent(eventName, {
					url: window.location.href,
					initial_funnel_type: initialFunnelType,
					funnel_type: funnel.slug,
					funnel_id: funnel.id,
					...eventProperties,
				})
			},
			setUserProperties: (...args) => {
				// Note: этот метод можно будет перенести в Jet после отказа от Amplitude
				store.dispatch('survey/handleUserAnalyticData', ...args)
				return analytic.setUserProperties(...args)
			},
			initDestinationPlugins: (...args) => analytic.initDestinationPlugins(...args),
			setUserId: (...args) => analytic.setUserId(...args),
		}
	},
}
