import SIMPLE_PAYWALL_1M_29_99_1W_4_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1W_4_99'
import SIMPLE_PAYWALL_1M_29_99_1M_17_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_17_99'
import SIMPLE_PAYWALL_3M_59_99_3M_29_99 from './paywall/SIMPLE_PAYWALL_3M_59_99_3M_29_99'
import SIMPLE_PAYWALL_1M_29_99_1M_44_00 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_44_00'
import SIMPLE_PREMIUM_1M_30 from './experiments/paywall/SIMPLE_PREMIUM_1M_30'

import SIMPLE_PAYWALL_1M_29_99_3M_15_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_15_00'
import SIMPLE_PAYWALL_1M_29_99_3M_14_95 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_14_95'
import SIMPLE_PAYWALL_1M_29_99_6M_11_95 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_11_95'
import SIMPLE_PAYWALL_1M_29_99_6M_12_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_12_00'
import SIMPLE_PAYWALL_1M_29_99_3M_30_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_3M_30_00'
import SIMPLE_PAYWALL_1M_29_99_6M_30_00 from './commitment/SIMPLE_PAYWALL_1M_29_99_6M_30_00'

import SIMPLE_UPGRADE_3M_59 from './upgrade/SIMPLE_UPGRADE_3M_59'
import SIMPLE_UPGRADE_3M_47_2 from './upgrade/SIMPLE_UPGRADE_3M_47_2'
import SIMPLE_UPGRADE_6M_79 from './upgrade/SIMPLE_UPGRADE_6M_79'
import SIMPLE_UPGRADE_6M_63_2 from './upgrade/SIMPLE_UPGRADE_6M_63_2'
import SIMPLE_UPGRADE_9M_99 from './upgrade/SIMPLE_UPGRADE_9M_99'
import SIMPLE_UPGRADE_9M_79_2 from './upgrade/SIMPLE_UPGRADE_9M_79_2'

import SIMPLE_PAYWALL_1M_29_99_1M_29_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1M_29_99'
import SIMPLE_PAYWALL_1M_29_99_1W_9_99 from './paywall/SIMPLE_PAYWALL_1M_29_99_1W_9_99'
import SIMPLE_PAYWALL_3M_59_99_3M_59_99 from './paywall/SIMPLE_PAYWALL_3M_59_99_3M_59_99'

// IS_APPLE_PAY_AS_DEFAULT_CTA_EXP
import SIMPLE_PAYWALL_1M_29_99_1W_3_99 from './experiments/paywall/SIMPLE_PAYWALL_1M_29_99_1W_3_99'
import SIMPLE_PAYWALL_1M_29_99_1M_14_39 from './experiments/paywall/SIMPLE_PAYWALL_1M_29_99_1M_14_39'
import SIMPLE_PAYWALL_3M_59_99_3M_23_99 from './experiments/paywall/SIMPLE_PAYWALL_3M_59_99_3M_23_99'

// IS_FEMALE_ACTIVITY_NEW_UPGRADES_EXP
import SIMPLE_UPGRADE_3M_53_97 from './experiments/upgrade/SIMPLE_UPGRADE_3M_53_97'
import SIMPLE_UPGRADE_3M_44_97 from './experiments/upgrade/SIMPLE_UPGRADE_3M_44_97'
import SIMPLE_UPGRADE_6M_77_95 from './experiments/upgrade/SIMPLE_UPGRADE_6M_77_95'
import SIMPLE_UPGRADE_6M_107_95 from './experiments/upgrade/SIMPLE_UPGRADE_6M_107_95'
import SIMPLE_UPGRADE_9M_79_95 from './experiments/upgrade/SIMPLE_UPGRADE_9M_79_95'
import SIMPLE_UPGRADE_9M_89_97 from './experiments/upgrade/SIMPLE_UPGRADE_9M_89_97'

// IS_OFFERING_TRIAL_WITH_COMMITMENTS_EXP
import SIMPLE_PAYWALL_TRIAL_COMM_TO_3M_2d_2_99 from './trial/SIMPLE_PAYWALL_TRIAL_COMM_TO_3M_2d_2_99'
import SIMPLE_PAYWALL_TRIAL_COMM_TO_6M_2d_2_99 from './trial/SIMPLE_PAYWALL_TRIAL_COMM_TO_6M_2d_2_99'
//IS_ADOBE_STYLE_PLANS_EXP
import SIMPLE_PREMIUM_V2_1M_29_99_3M_40 from './experiments/paywall/SIMPLE_PREMIUM_V2_1M_29_99_3M_40'
import SIMPLE_PREMIUM_1M_29_99_6M_66 from './experiments/paywall/SIMPLE_PREMIUM_1M_29_99_6M_66'

export default {
	SIMPLE_PAYWALL_1M_29_99_1W_3_99,
	SIMPLE_PAYWALL_1M_29_99_1W_4_99,
	SIMPLE_PAYWALL_1M_29_99_1M_14_39,
	SIMPLE_PAYWALL_1M_29_99_1M_17_99,
	SIMPLE_PAYWALL_3M_59_99_3M_23_99,
	SIMPLE_PAYWALL_3M_59_99_3M_29_99,
	SIMPLE_PAYWALL_1M_29_99_1M_44_00,
	SIMPLE_PREMIUM_1M_30,

	SIMPLE_PAYWALL_1M_29_99_3M_15_00,
	SIMPLE_PAYWALL_1M_29_99_3M_14_95,
	SIMPLE_PAYWALL_1M_29_99_6M_11_95,
	SIMPLE_PAYWALL_1M_29_99_6M_12_00,
	SIMPLE_PAYWALL_1M_29_99_3M_30_00,
	SIMPLE_PAYWALL_1M_29_99_6M_30_00,
	SIMPLE_PREMIUM_V2_1M_29_99_3M_40,
	SIMPLE_PREMIUM_1M_29_99_6M_66,

	SIMPLE_UPGRADE_3M_59,
	SIMPLE_UPGRADE_3M_47_2,
	SIMPLE_UPGRADE_6M_79,
	SIMPLE_UPGRADE_6M_63_2,
	SIMPLE_UPGRADE_9M_99,
	SIMPLE_UPGRADE_9M_79_2,

	SIMPLE_PAYWALL_1M_29_99_1M_29_99,
	SIMPLE_PAYWALL_1M_29_99_1W_9_99,
	SIMPLE_PAYWALL_3M_59_99_3M_59_99,

	SIMPLE_UPGRADE_3M_53_97,
	SIMPLE_UPGRADE_3M_44_97,
	SIMPLE_UPGRADE_6M_77_95,
	SIMPLE_UPGRADE_6M_107_95,
	SIMPLE_UPGRADE_9M_79_95,
	SIMPLE_UPGRADE_9M_89_97,

	SIMPLE_PAYWALL_TRIAL_COMM_TO_3M_2d_2_99,
	SIMPLE_PAYWALL_TRIAL_COMM_TO_6M_2d_2_99,
}
