<template>
	<div class="theme-provider">
		<slot></slot>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'ThemeProvider',

	watch: {
		theme: {
			handler(theme) {
				document.body.setAttribute('data-theme', theme)
				this.$store.commit('setAppTheme', theme)
			},
			immediate: true,
		},
	},

	computed: {
		...mapGetters({
			appAreaConfig: 'location/getAppAreaConfig',
		}),

		theme() {
			return this.appAreaConfig.funnel.theme ?? 'light'
		},
	},
}
</script>
