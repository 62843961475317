// prettier-ignore
export type AppArea =
	// BAU
	| 'main' | 'female' | 'male' | 'aff-main' | 'aff-female' | 'aff-male' | 'wildcard-main' | 'wildcard-female' | 'wildcard-male'
	// Activity
	| 'main-activity' | 'activity-female-v2' | 'aff-activity-female' | 'activity-male'
	// Pilates
	| 'activity-female-pilates' | 'aff-activity-female-pilates'
	// Yoga
	| 'main-yoga' | 'activity-female-yoga' | 'aff-activity-female-yoga' | 'activity-male-yoga'
	// Somatic Yoga
	| 'activity-female-somatic-yoga'
	// Walking
	| 'main-walking' | 'activity-female-walking' | 'activity-male-walking'

// prettier-ignore
type FunnelSlug =
	// BAU
	| 'main' | 'female' | 'male'
	// Activity
	| 'main-activity' | 'female-activity-v2' | 'male-activity'
	// Pilates
	| 'activity-female-pilates'
	// Yoga
	| 'main-yoga' | 'female-activity-yoga' | 'male-activity-yoga'
	// Somatic Yoga
	| 'female-activity-somatic-yoga'
	// Walking
	| 'main-walking' | 'female-activity-walking' | 'male-activity-walking'

type AppAreaOptions = {
	userFlags?: Array<
		'hasAccessToPilatesVideos' | 'hasAccessToYogaVideos' | 'hasAccessToSomaticYogaVideos' | 'hasAccessToWalkingVideos'
	>
	userTags?: Array<'activity' | 'pilates' | 'yoga' | 'walking' | 'somatic_yoga'>
}

type FunnelTheme = 'light' | 'navy'
type FunnelLocale = Array<
	'en' | 'de-DE' | 'fr-FR' | 'tr' | 'sv' | 'pl' | 'pt-BR' | 'it-IT' | 'es-ES' | 'es-419' | 'da' | 'nb' | 'nl'
>

type LandingConfig = {
	route: {
		path: string
		name: string
	}
}

type FunnelConfig = {
	id: string
	route: {
		path: string
		name: string
	}
	slug: FunnelSlug
	theme: FunnelTheme
	locale: FunnelLocale
	redirectTo?: {
		sex: {
			male: AppAreaConfig
			female: AppAreaConfig
		}
	}
}

type PrePaywallConfig = {}
type PaywallConfig = {
	routeName: string
}
type UpsellConfig = {}

export type AppAreaConfig = {
	appArea: AppArea
	options?: AppAreaOptions
	landing: LandingConfig
	funnel: FunnelConfig
	prePaywall: PrePaywallConfig
	paywall: PaywallConfig
	upsell: UpsellConfig
	overrides?: (params: OverridesParams) => OverridesObject | undefined
}

export const isAppAreaConfig = (config: unknown): config is AppAreaConfig => {
	return (
		typeof config === 'object' &&
		config !== null &&
		'appArea' in config &&
		'landing' in config &&
		'funnel' in config &&
		'prePaywall' in config &&
		'paywall' in config &&
		'upsell' in config
	)
}

export type OverridesParams = {
	country: string
}

export type OverridesObject = {
	funnel?: {
		id: string
		locale: FunnelLocale
	}
	paywall?: {
		routeName: string
	}
}

export type DomainConfig = {
	[pathname: string]: AppAreaConfig
}

export type DomainsConfig = {
	[domain: string]: {
		experiments: boolean
		pathnames: DomainConfig
	}
}

export type DomainMatch = {
	domainConfig: {
		experiments: boolean
		pathnames: { [key: string]: AppAreaConfig | { appArea: string } }
	}
	regex: RegExp
}

export type PartialExperimentConfig = {
	[K in AppArea]?: Partial<AppAreaConfig>
}
