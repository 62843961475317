import type { PartialExperimentConfig } from '../@types'

export default {
	female: {
		funnel: {
			id: 'ob-female-social-proof-first',
			locale: ['en'],
		},
	},
	'activity-female-v2': {
		funnel: {
			id: 'ob-female-activity-social-proof-first',
			locale: ['en'],
		},
	},
	'activity-female-pilates': {
		funnel: {
			id: 'ob-female-pilates-social-proof-first',
			locale: ['en'],
		},
	},
} as PartialExperimentConfig
