import type { PartialExperimentConfig } from '../@types'

export default {
	female: {
		funnel: {
			id: 'ob-female-free-dietitian-video-consultation',
			locale: ['en'],
		},
	},
} as PartialExperimentConfig
