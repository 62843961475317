import bau, { bauAff, bauWildcard } from './domains/simple-life-app.com'
import activity, { activityAff } from './domains/activity.simple-life-app.com'

import {
	AppAreaConfig,
	DomainsConfig,
	DomainMatch,
	isAppAreaConfig,
	OverridesObject,
	OverridesParams,
	AppArea,
} from './@types'
import experimentsConfig from './experiments'

const config: DomainsConfig = {
	'simple-life-app.com': {
		experiments: true,
		pathnames: bau,
	},
	'activity.simple-life-app.com': {
		experiments: true,
		pathnames: activity,
	},
	'aff.simple-life-app.com': {
		experiments: false,
		pathnames: bauAff,
	},
	'aff.activity.simple-life-app.com': {
		experiments: false,
		pathnames: activityAff,
	},
	'*.simple-life-app.com': {
		experiments: false,
		pathnames: bauWildcard,
	},
}

// export const SERVICE_PREFIXES: { [key: string]: { appArea: string } } = {
// 	'/account': {
// 		appArea: 'account',
// 	},
// 	'/redirect': {
// 		appArea: 'redirect',
// 	},
// }

/**
 * Array of domain configurations with their corresponding regex patterns.
 * Used for matching hostnames and retrieving domain-specific settings.
 *
 * @type {DomainMatch[]}
 *
 * @property {Object} domainConfig - Configuration for the domain
 * @property {boolean} domainConfig.experiments - Whether experiments are enabled for this domain
 * @property {Object} domainConfig.pathnames - Object containing path configurations and service prefixes
 * @property {RegExp} regex - Regular expression for matching the domain pattern
 *
 * @example
 * // Example of a domain match object:
 * {
 *   domainConfig: {
 *     experiments: true,
 *     pathnames: { '/': {...}, '/account': {...} }
 *   },
 *   regex: /^simple-life-app\.com$/
 * }
 */
export const DOMAINS_FROM_CONFIG = Object.entries(config).reduce<DomainMatch[]>((result, [domain, domainConfig]) => {
	return [
		...result,
		{
			domainConfig: { ...domainConfig, pathnames: { ...domainConfig.pathnames /*, ...SERVICE_PREFIXES */ } },
			regex: new RegExp(`^${domain.replace(/^\*/, '.*')}$`),
		},
	]
}, [])

/**
 * Array of domain names where A/B testing experiments are enabled.
 * Used to determine which domains should participate in experiments.
 *
 * @type {string[]}
 *
 * @example
 * // Returns array like:
 * [
 *   'simple-life-app.com',
 *   'activity.simple-life-app.com'
 * ]
 */
export const DOMAINS_WITH_EXPERIMENTS = Object.keys(config).filter((key) => config[key].experiments)

/**
 * Array of tuples containing URL path prefixes and their corresponding regex patterns.
 * Used for validating and matching URL paths across different domains.
 * Includes both domain-specific pathnames and service prefixes (like '/account', '/redirect').
 *
 * @type {[string, RegExp][]}
 *
 * @example
 * // Returns array like:
 * [
 *   ['/account', /^\/account(?:\/[^-][^/]*)*\/?$/],
 *   ['/redirect', /^\/redirect(?:\/[^-][^/]*)*\/?$/],
 *   ['/female', /^\/female(?:\/[^-][^/]*)*\/?$/]
 * ]
 */
export const DOMAINS_PREFIXES = [
	...new Set(
		Object.values(config)
			.map((domainConfig) => Object.keys(domainConfig.pathnames))
			.flat()
			.filter((prefix) => prefix !== '/'),
		// .concat(Object.keys(SERVICE_PREFIXES)),
	),
].map((prefix) => [prefix, new RegExp(`^${prefix}(?:/[^-][^/]*)*/?$`)])

/**
 * Retrieves specific configuration part from active experiments for given app area.
 *
 * @param {AppArea} appArea - The application area to get experiments for (e.g., 'female', 'activity-female-yoga')
 * @param {'landing' | 'funnel' | 'prePaywall' | 'paywall' | 'upsell'} part - The configuration part to retrieve
 * @param {Function} $absmartly - A/B Smartly instance
 *
 * @returns {Object|null} Configuration part from the active experiment or null if no active experiments found
 *
 * @example
 * // For funnel configuration:
 * getAppAreaExperimentPart('female', 'funnel', this.$absmartly)
 */
const getAppAreaExperimentPart = (
	appArea: AppArea,
	part: 'landing' | 'funnel' | 'prePaywall' | 'paywall' | 'upsell',
	$absmartly: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
	const appAreaExperiments = experimentsConfig[appArea]

	if (typeof appAreaExperiments !== 'object' || Object.keys(appAreaExperiments).length === 0) {
		return null
	}

	for (const experimentName of Object.keys(appAreaExperiments)) {
		const experimentAreaConfig = appAreaExperiments[experimentName][appArea]

		if (!experimentAreaConfig) {
			continue
		}

		if (experimentAreaConfig[part]) {
			const variant = part === 'funnel' ? $absmartly.assign(experimentName) : $absmartly.treatment(experimentName)

			if (variant) {
				return experimentAreaConfig[part]
			}
		}
	}

	return null
}

/**
 * Applies country-specific overrides to the area configuration if they exist.
 * Used to customize configuration based on user's country.
 *
 * @param {AppAreaConfig | { appArea: string }} areaConfig - Base configuration for the area
 * @param {OverridesParams} params - Parameters for override conditions
 * @param {string} params.country - Country code to check overrides against
 *
 * @returns {AppAreaConfig} Modified or original area configuration
 *
 * @example
 * // Configuration with country-specific override
 * const config = {
 *   appArea: 'female',
 *   funnel: { type: 'default' },
 *   overrides: ({ country }) => country === 'US'
 *     ? { funnel: { type: 'us-specific' } }
 *     : undefined
 * }
 *
 * // Applying overrides
 * applyOverrides(config, { country: 'US' })
 * // Returns: { appArea: 'female', funnel: { type: 'us-specific' } }
 */
const applyOverrides = (
	areaConfig: AppAreaConfig | { appArea: string },
	{ country }: OverridesParams,
): AppAreaConfig => {
	if (isAppAreaConfig(areaConfig) && typeof areaConfig.overrides === 'function') {
		const overrides: OverridesObject | undefined = areaConfig.overrides({ country })

		if (typeof overrides === 'object') {
			return { ...areaConfig, ...overrides } as AppAreaConfig
		}
	}

	return areaConfig as AppAreaConfig
}

/**
 * Applies funnel experiment configuration to the base area configuration if an active experiment exists.
 *
 * @param {AppAreaConfig | { appArea: string }} areaConfig - Base configuration for the area
 * @param {Function} $absmartly - A/B Smartly instance
 *
 * @returns {AppAreaConfig} Configuration with applied funnel experiment or original configuration
 */
const applyFunnelExperiments = (areaConfig: AppAreaConfig | { appArea: string }, $absmartly): AppAreaConfig => {
	const funnelExperiment = getAppAreaExperimentPart(areaConfig.appArea as AppArea, 'funnel', $absmartly)

	if (isAppAreaConfig(areaConfig) && funnelExperiment) {
		return { ...areaConfig, funnel: { ...areaConfig.funnel, ...funnelExperiment } }
	}

	return areaConfig as AppAreaConfig
}

/**
 * Defines a funnel configuration based on either app area or initial path.
 * If appArea is provided, finds configuration by matching app area.
 * Otherwise, uses initialPath to find configuration in domain's pathnames.
 *
 * @param {Object} params - Parameters for funnel definition
 * @param {string|null} params.appArea - App area identifier (e.g., 'female', 'activity-female-yoga').
 *                                      Required if initialPath is not provided
 * @param {string|null} params.initialPath - URL path to match against domain's pathname configurations.
 *                                          Required if appArea is not provided
 * @param {string} params.country - Country code for applying country-specific overrides
 * @param {string} params.domain - Domain name to match against domain configurations
 * @param {Function} $absmartly - A/B Smartly instance
 *
 * @returns {AppAreaConfig} Funnel configuration with applied overrides and experiments
 *
 */
// eslint-disable-next-line import/no-unused-modules
export function defineFunnelByParams(params, $absmartly) {
	const { appArea, country, domain, initialPath } = params
	let currentAreaConfig

	if (appArea) {
		currentAreaConfig = DOMAINS_FROM_CONFIG.map((item) => Object.values(item.domainConfig.pathnames))
			.flat()
			.find((v) => v.appArea === appArea)
	} else if (initialPath) {
		const domainConfig = DOMAINS_FROM_CONFIG.find((item) => item.regex.test(domain))?.domainConfig

		currentAreaConfig = domainConfig?.pathnames[initialPath] || domainConfig?.pathnames['/']
	}

	if (!currentAreaConfig) {
		import('@/modules/Sentry.js').then((Sentry) => {
			Sentry.default.withScope((scope) => {
				scope.setTags({
					funnelTypeError: true,
				})
				scope.setExtras(params)
				scope.captureMessage(`No funnel found for this ${appArea ? 'app area' : 'domain'}`)
			})
		})

		currentAreaConfig = getDefaultAppAreaConfigByHostname(domain)
	}

	currentAreaConfig = applyOverrides(currentAreaConfig, { country })

	if ($absmartly) {
		currentAreaConfig = applyFunnelExperiments(currentAreaConfig, $absmartly)
	}

	return currentAreaConfig
}

export const getLandingPathByPaywallRoute = (hostname: string, paywallRoute: string) => {
	const domainConfig = DOMAINS_FROM_CONFIG.find((item) => item.regex.test(hostname))?.domainConfig

	if (!domainConfig) {
		return '/'
	}

	const pathname = Object.entries(domainConfig.pathnames).find(([, config]) => {
		return isAppAreaConfig(config) && config.paywall?.routeName === paywallRoute
	})?.[0]

	return pathname || '/'
}

export const getDefaultAppAreaConfigByHostname = (hostname: string) => {
	const domainConfig = DOMAINS_FROM_CONFIG.find((item) => item.regex.test(hostname))?.domainConfig

	if (!domainConfig) {
		return bau['/']
	}

	return domainConfig.pathnames['/']
}
