import api from '@/api'

export default {
	namespaced: true,
	state: {
		/**
		 * Remote user flags are stored in the backend. These flags are used to control state of user's profile.
		 * @type {Object}
		 */
		remote: {},
		/**
		 * Local user flags are stored in the frontend and synced with localStorage.
		 */
		local: {
			workbooksBundle: false,
			organicTrafficOnboarding: false,

			freebieAccess: false,

			/** Start IS_EMAIL_BEFORE_PREDICT_EXP */
			brazeEmailCollectionEventSent: false,
			brazePaywallVisitEventSent: false,
			isGdprPassed: false,
			/** End IS_EMAIL_BEFORE_PREDICT_EXP */

			additionalPaywallDiscountApplied: false,
			scratchCardUsed: false,
			hotjarConsentPassed: false,
			cancellationFeeType: null,
			brazeAssesmentEmailSent: false,

			// IS_COMMITMENT_CANCELLATION_FEES_I2_EXP
			commitmentCancellationFlowPassed: false,
		},
	},
	getters: {
		getFlags(state) {
			return {
				remote: state.remote,
				local: state.local,
			}
		},
		/**
		 * Check if any of the flags is greater than current time.
		 * This needs for checking if user has possibility to do some actions.
		 * @param store
		 * @param {Array<string>|string} flags
		 * @returns {boolean}
		 */
		isAnyOfFlagsAfterCurrentTime: (state) => (flags) => {
			if (typeof flags === 'string') {
				flags = [flags]
			}

			for (const flag of flags) {
				const remoteFlag = state.remote[flag]

				if (Number.isInteger(remoteFlag) && remoteFlag > Date.now()) {
					return true
				}
			}

			return false
		},
		getIsOrganicTrafficOnboarding(state) {
			return state.local.organicTrafficOnboarding
		},
	},
	mutations: {
		setRemoteFlags(state, flags) {
			state.remote = flags
		},
		updateLocalFlags(state, payload) {
			state.local = { ...state.local, ...payload }
		},
	},
	actions: {
		async updateUserFlags(store, payload) {
			const remoteFlags = store.getters.getFlags.remote
			const flags = { ...remoteFlags, ...payload }

			await api.setUserFlags(flags)

			store.commit('setRemoteFlags', flags)

			if (store.rootGetters.hasAlreadyExistedAccount) {
				api.mergeUserWithAlreadyExisted()
			}
		},
	},
}
