export const LS_EXPIRATION_MINUTES = 7 * 24 * 60 // one week

export const VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY = {
	intermittentFasting: {
		just_name: 'Just the name',
		few_things: 'I know a few things',
		everything: 'Yes, I know everything about it!',
	},
	meals: {
		1: '1-2',
		2: '2-3',
		3: '3-4',
		4: '4+',
	},
	carbs: {
		often: 'very_often',
		sometimes: 'sometimes',
		rarely: 'rarely',
		never: 'not_at_all',
	},
	sleep: {
		long_time_fall_asleep: 'long_time',
		go_after_11: 'after_11pm',
		less_than_7: '7_hours',
		more_than_10: '10_hours',
		never_feel_rested: 'restless_sleep',
		'8_hours': '8_hours',
	},
	work: {
		sedentary: 'sedentary',
		moderately_active: 'mid_active',
		very_active: 'phisically_demanding',
	},
	age: {
		'18-29': '18-29',
		'30-39': '30-39',
		'40-49': '40-49',
		'50-59': '50-59',
		'60-69': '60-69',
		'70+': '70-70',
	},
	motivation_event: {
		vacation: 'vacation',
		wedding: 'wedding',
		competition: 'competition',
		summer: 'summer',
		school: 'reunion',
		family: 'family',
		birthday: 'birthday',
		dont_have: 'no',
	},
	rq_1: {
		diet_activity_need_impoving: 'My diet and activity need a lot of fine-tuning',
		diet_activity_could_improved: 'I try to practice healthy habits',
		trying_healthy_lifestyle: 'I mostly eat well and stay active',
	},
	rq_2: {
		sandwiches_wraps: 'I tend to eat sandwiches or wraps',
		soups_salads: 'I tend to eat soups and salads',
		fast_food: 'Fast food',
	},
	rq_3: {
		three_or_fewer: 'I almost always eat three or fewer meals per day',
		three_and_snacks: 'I almost always eat at least three meals, plus several snacks a day',
		it_depends: 'It depends — sometimes less than three meals, sometimes more',
	},
	bodyType: {
		circle: 'rounded',
		triangle: 'triangle',
		inverted_triangle: 'inverted_triangle',
		rectangle: 'rectangle',
		hourglass: 'hourglass',
	},
	health_conditions: {
		anorexia: 'anorexia',
		bulimia: 'bulimia',
		diabetes: 'diabetes',
		high_blood_pressure: 'high_blood_pressure',
		high_cholesterol: 'high_cholesterol',
		mental_health_issues: 'stress_anxiety_depression',
		other: 'other',
		dont_have: 'none',
	},
	vitamins: 'vitamins',
	hormones: 'hormones',
	antibiotics: 'antibiotics',
	other: 'other',
	not_taking: 'none_of_this',
}

export const SUPPORTED_CURRENCIES = ['EUR', 'CAD', 'GBP', 'AUD', 'DKK', 'SEK', 'NOK', 'BRL', 'MXN', 'TRY']

export const GDPR_COUNTRIES = [
	'AT', // Austria
	'BE', // Belgium
	'BG', // Bulgaria
	'CY', // Cyprus
	'CZ', // Czech Republic
	'DE', // Germany
	'DK', // Denmark
	'EE', // Estonia
	'ES', // Spain
	'FI', // Finland
	'FR', // France
	'GB', // United Kingdom
	'GR', // Greece
	'HU', // Hungary
	'IE', // Ireland
	'IS', // Iceland
	'IT', // Italy
	'LT', // Lithuania
	'LU', // Luxembourg
	'LV', // Latvia
	'MT', // Malta
	'NL', // Netherlands
	'NO', // Norway
	'PL', // Poland
	'PT', // Portugal
	'RO', // Romania
	'SE', // Sweden
	'SI', // Slovenia
	'SK', // Slovakia
]

export const ANALYTIC_AGE_TO_AGE_RANGE_MAP = {
	18: '18-29',
	30: '30-39',
	40: '40-49',
	50: '50-59',
	60: '60+',
	70: '70+',
}

export const REQUIRED_ANALYTIC_PROPERTIES = ['Age Range', 'Sex', 'Weight', 'Height', 'TargetWeight']
export const REQUIRED_ANALYTIC_PROPERTIES_VALUES_MAP = {
	// 'Age Range': ['18-29', '30-39', '40-49', '50-59', '60+'],
	Sex: ['male', 'female'],
}

// Note: list of valid motivation events, needed for avoid broken copies across app (weight loss charts, etc)
export const ALLOW_MOTIVATION_EVENT_LIST = [
	'birthday',
	'competition',
	'holiday',
	'family',
	'other',
	'school',
	'reunion',
	'summer',
	'vacation',
	'wedding',
	'fun_adventure',
	'baby_weight',
	'dont_have',
]

export const LEGACY_UX_EVENTS = {
	OnboardingHighTechWeightLossInfoScreen: 'ONBOARDING_HIGH_TECH_WEIGHT_LOSS_INFO_SCREEN',
	OnboardingHealthTroublesScreen: 'ONBOARDING_HEALTH_TROUBLES_SCREEN',
	OnboardingTailoredPlanPart1InfoScreen: 'ONBOARDING_TAILORED_PLAN_PART1_INFO_SCREEN',
	OnboardingTailoredPlanPart2InfoScreen: 'ONBOARDING_TAILORED_PLAN_PART2_INFO_SCREEN',
	OnboardingDescribeYourOccupationScreen: 'ONBOARDING_DESCRIBE_YOUR_OCCUPATION_SCREEN',
	OnboardingWhereYouLiveScreen: 'ONBOARDING_WHERE_YOU_LIVE_SCREEN',
	OnboardingSleepRestedScreen: 'ONBOARDING_SLEEP_RESTED_SCREEN',
	OnboardingStressHowOftenScreen: 'ONBOARDING_STRESS_HOW_OFTEN_SCREEN',
	OnboardingTailoredPlanPart3InfoScreen: 'ONBOARDING_TAILORED_PLAN_PART3_INFO_SCREEN',
	OnboardingWeightLossSpeed2Screen: 'ONBOARDING_WEIGHT_LOSS_SPEED2_SCREEN',
}

export const CUSTOM_ANALYTIC_EVENTS = [
	'OnboardingHeightScreenDone',
	'OnboardingWeightScreenDone',
	'OnboardingWeightGoalScreenDone',
	'OnboardingHappyWeightScreenDone',
	'recommendedWeightRange',
	'Height',
	'Weight',
	'TargetWeight',
	'Conditions',
	'Diet',
]

export const EXTENTED_USER_MODEL_TYPES_MAP = {
	height: 'height',
	weight: 'weight',
	birthDate: 'string',
	targetWeight: 'weight',
	sex: 'string',
	intermittent_fasting: 'array',
	data: {
		age: 'age',
		goal: 'array',
		gender: 'array',
		strategy: 'array',
		lunch: 'array',
		feelings: 'array',
		hopings: 'array',
		happy_weight_1: 'array',
		happy_weight_2: 'array',
		meal_shedule: 'array',
		diet: 'array',
		meals: 'array',
		work: 'array',
		sport: 'array',
		sleep: 'array',
		health_conditions: 'array',
		liveProblem: 'array',
		drugs: 'array',
		bodyType: 'array',
		carbs: 'string',
		water: 'array',
		measurement_system: 'string',
		measurement_system_height: 'string',
		measurement_system_weight: 'string',
		area: 'array',
		rq_1: 'string',
		rq_2: 'string',
		rq_3: 'string',
		rq_4: 'string',
		adt: 'array',
		motivation_event: 'motivation_event',
		motivation_event_custom: 'string',
		special_event: 'string',
		planUp: 'boolean',
		planDown: 'boolean',
		following_statement_1: 'number',
		following_statement_2: 'number',
		following_statement_3: 'number',
		following_statement_4: 'number',
		following_statement_5: 'number',
		following_statement_6: 'number',
		priorites: 'array',
		chronotype: 'array',
		cancel_reason: 'array',
		second_goal: 'array',
		at_happy_weight: 'array',
		confidence_in_reaching: 'array',
		current_priorities: 'array',
		eating_habits: 'array',
		gain_weight_reason: 'array',
		last_happy_weight: 'array',
		like_to_boost: 'array',
		feel: 'array',
		main_reason: 'array',
		mental_health_services: 'array',
		explore: 'array',
		motivation_to_lose_weight: 'array',
		see_my_self: 'array',
		snacking_triggers: 'array',
		typical_day: 'array',
		typical_meal: 'array',
		understanding_mindset: 'array',
		weight_loss_apps: 'array',
		weight_loss_attempts: 'array',
		weight_loss_goal: 'array',
		weight_loss_goals: 'array',
		what_make_uncertain: 'array',
		your_plan_ready: 'array',
		eating_disorder: 'array',
		pregnancy: 'array',
		gender_identity: 'gender_identity',
		non_binary_identity: 'string',
	},
}
