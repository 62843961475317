import type { PartialExperimentConfig } from '../@types'

export default {
	'activity-female-yoga': {
		funnel: {
			id: 'ob-female-yoga-exercises',
			locale: ['en'],
		},
	},
} as PartialExperimentConfig
