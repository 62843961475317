export default {
	id: 'SIMPLE_PREMIUM_V2_1M_29_99_3M_40',
	providers: {
		paltaMap: {
			USD: 'simple_premium_v2_1m_29_99_3m_40_usd',
			EUR: 'simple_premium_v2_1m_29_99_3m_40_eur',
		},
	},
	trial: {
		days: 90,
		priceMap: {
			USD: 40,
			EUR: 40,
		},
		originalPriceMap: {
			USD: 29.99,
			EUR: 29.99,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: 'first 3 months',
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
