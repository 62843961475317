import { AppArea, PartialExperimentConfig } from '@/funnels/@types'

import web_autotest_female_funnel_check from './web_autotest_female_funnel_check'
import web_social_proof_first from './web_social_proof_first'
import web_female_yoga_exercises_examples from './web_female_yoga_exercises_examples'
import web_female_chair_yoga_event_personalization from './web_female_chair_yoga_event_personalization'
import web_new_start_female_walking from './web_new_start_female_walking'
import web_female_emotional_narrative from './web_female_emotional_narrative'
import web_fasting_welcome_like_activity from './web_fasting_welcome_like_activity'
import web_female_somatic_yoga_yogago from './web_female_somatic_yoga_yogago'
import web_back_to_fasting from './web_back_to_fasting'
import web_free_dietitian_video_consultation from './web_free_dietitian_video_consultation'

const experimentsConfig: Partial<Record<AppArea, { [key: string]: PartialExperimentConfig }>> = {
	main: {
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
	},
	female: {
		web_autotest_female_funnel_check,
		web_female_emotional_narrative,
		web_social_proof_first,
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
		web_free_dietitian_video_consultation,
	},
	male: {
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
	},

	'main-activity': {},
	'activity-female-v2': {
		web_social_proof_first,
	},
	'activity-male': {},

	'activity-female-pilates': {
		web_social_proof_first,
	},

	'main-yoga': {},
	'activity-female-yoga': {
		web_female_yoga_exercises_examples,
		web_female_chair_yoga_event_personalization,
	},
	'activity-male-yoga': {},

	'activity-female-somatic-yoga': {
		web_female_somatic_yoga_yogago,
	},

	'main-walking': {},
	'activity-female-walking': {
		web_new_start_female_walking,
	},
	'activity-male-walking': {},
}

export default experimentsConfig
