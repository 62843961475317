export default {
	id: 'SIMPLE_PAYWALL_TRIAL_COMM_TO_3M_2d_2_99',
	providers: {
		paltaMap: {
			USD: 'simple_premium_trial_comm_to_3m_2d_2_99_usd',
			EUR: 'simple_premium_trial_comm_to_3m_2d_2_99_eur',
		},
	},
	trial: {
		days: 2,
		priceMap: {
			USD: 2.99,
			EUR: 2.99,
		},
		originalPriceMap: {},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
		},
	},
	options: ($t) => ({
		commitmentPeriodMonths: 3,
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.90_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.90_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.90_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.90_days.sub_period_text'), // 'monthly'
	}),
}
