import { AppAreaConfig, DomainConfig, OverridesObject } from '@/funnels/@types'
import merge from 'lodash.merge'

const femaleActivity: AppAreaConfig = {
	appArea: 'activity-female-v2',
	options: {
		userTags: ['activity'],
	},
	landing: {
		route: {
			path: '/activity-f',
			name: 'female-activity-funnel',
		},
	},
	funnel: {
		id: 'ob-female-activity',
		route: {
			path: '/activity-f/survey/ob/:page*',
			name: 'female-activity-onboarding',
		},
		slug: 'female-activity-v2',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity',
	},
	upsell: {},
}

const maleActivity: AppAreaConfig = {
	appArea: 'activity-male',
	options: {
		userTags: ['activity'],
	},
	landing: {
		route: {
			path: '/activity-m',
			name: 'male-activity-funnel',
		},
	},
	funnel: {
		id: 'ob-male-activity',
		route: {
			path: '/activity-m/survey/:page*',
			name: 'male-activity-onboarding',
		},
		slug: 'male-activity',
		theme: 'navy',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-male-activity',
	},
	upsell: {},
}

const mainActivity: AppAreaConfig = {
	appArea: 'main-activity',
	options: {
		userTags: ['activity'],
	},
	landing: {
		route: null, // this route defined in router/index.js
	},
	funnel: {
		id: 'ob-activity-gender-selection',
		route: {
			path: '/activity-survey/:page*',
			name: 'activity-survey',
		},
		slug: 'main-activity',
		theme: 'light',
		locale: ['en'],
		redirectTo: {
			sex: {
				male: maleActivity,
				female: femaleActivity,
			},
		},
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity',
	},
	upsell: {},
}

const pilatesFemale: AppAreaConfig = {
	appArea: 'activity-female-pilates',
	options: {
		userFlags: ['hasAccessToPilatesVideos'],
		userTags: ['pilates'],
	},
	landing: {
		route: {
			path: '/pilates-f',
			name: 'female-pilates-funnel',
		},
	},
	funnel: {
		id: 'ob-yoga-narrative-on-pilates',
		route: {
			path: '/pilates-f/survey/ob/:page*',
			name: 'female-activity-pilates',
		},
		slug: 'activity-female-pilates',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-pilates',
	},
	upsell: {},
	overrides: ({ country }): OverridesObject => {
		if (country === 'us') {
			return {
				paywall: {
					routeName: 'plan-female-activity-pilates-commitment',
				},
			}
		}
	},
}

const yogaFemale: AppAreaConfig = {
	appArea: 'activity-female-yoga',
	options: {
		userFlags: ['hasAccessToYogaVideos'],
		userTags: ['yoga'],
	},
	landing: {
		route: {
			path: '/yoga-f',
			name: 'female-yoga-funnel',
		},
	},
	funnel: {
		id: 'ob-female-activity-chair-yoga',
		route: {
			path: '/yoga-f/survey/ob/:page*',
			name: 'female-activity-chair-yoga',
		},
		slug: 'female-activity-yoga',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-chair-yoga',
	},
	upsell: {},
}

const yogaMale: AppAreaConfig = {
	appArea: 'activity-male-yoga',
	options: {
		userFlags: ['hasAccessToYogaVideos'],
		userTags: ['yoga'],
	},
	landing: {
		route: {
			path: '/yoga-m',
			name: 'male-yoga-funnel',
		},
	},
	funnel: {
		id: 'ob-male-activity-chair-yoga',
		route: {
			path: '/yoga-m/survey/ob/:page*',
			name: 'male-activity-chair-yoga',
		},
		slug: 'male-activity-yoga',
		theme: 'light',
		locale: ['en'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-male-activity-chair-yoga',
	},
	upsell: {},
}

const yogaMain: AppAreaConfig = {
	appArea: 'main-yoga',
	options: {
		userFlags: ['hasAccessToYogaVideos'],
		userTags: ['yoga'],
	},
	landing: {
		route: {
			path: '/yoga',
			name: 'main-yoga-funnel',
		},
	},
	funnel: {
		id: 'ob-yoga-gender-selection',
		route: {
			path: '/yoga/survey/ob/:page*',
			name: 'yoga-survey',
		},
		slug: 'main-yoga',
		theme: 'light',
		locale: ['en'],
		redirectTo: {
			sex: {
				male: yogaMale,
				female: yogaFemale,
			},
		},
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-chair-yoga',
	},
	upsell: {},
}

const somaticYogaFemale: AppAreaConfig = {
	appArea: 'activity-female-somatic-yoga',
	options: {
		userFlags: ['hasAccessToSomaticYogaVideos'],
		userTags: ['somatic_yoga'],
	},
	landing: {
		route: {
			path: '/somatic-yoga',
			name: 'somatic-yoga-funnel',
		},
	},
	funnel: {
		id: 'ob-female-activity-somatic-yoga',
		route: {
			path: '/somatic-yoga/survey/ob/:page*',
			name: 'female-activity-somatic-yoga',
		},
		slug: 'female-activity-somatic-yoga',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-somatic-yoga',
	},
	upsell: {},
}

const walkingFemale: AppAreaConfig = {
	appArea: 'activity-female-walking',
	options: {
		userFlags: ['hasAccessToWalkingVideos'],
		userTags: ['walking'],
	},
	landing: {
		route: {
			path: '/walking-f',
			name: 'walking-funnel',
		},
	},
	funnel: {
		id: 'ob-female-activity-walking',
		route: {
			path: '/walking-f/survey/ob/:page*',
			name: 'female-activity-walking',
		},
		slug: 'female-activity-walking',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-walking',
	},
	upsell: {},
	overrides: ({ country }): OverridesObject => {
		if (country === 'us') {
			return {
				paywall: {
					routeName: 'plan-female-activity-walking-us',
				},
			}
		}
	},
}

const walkingMale: AppAreaConfig = {
	appArea: 'activity-male-walking',
	options: {
		userFlags: ['hasAccessToWalkingVideos'],
		userTags: ['walking'],
	},
	landing: {
		route: {
			path: '/walking-m',
			name: 'male-walking-funnel',
		},
	},
	funnel: {
		id: 'ob-male-activity-walking',
		route: {
			path: '/walking-m/survey/ob/:page*',
			name: 'male-activity-walking',
		},
		slug: 'male-activity-walking',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-male-activity-walking',
	},
	upsell: {},
}

const walkingMain: AppAreaConfig = {
	appArea: 'main-walking',
	options: {
		userFlags: ['hasAccessToWalkingVideos'],
		userTags: ['walking'],
	},
	landing: {
		route: {
			path: '/walking',
			name: 'main-walking-funnel',
		},
	},
	funnel: {
		id: 'ob-walking-gender-selection',
		route: {
			path: '/walking/survey/ob/:page*',
			name: 'walking-survey',
		},
		slug: 'main-walking',
		theme: 'light',
		locale: ['en'],
		redirectTo: {
			sex: {
				male: walkingMale,
				female: walkingFemale,
			},
		},
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-activity-walking',
	},
	upsell: {},
}

export const activityAff: DomainConfig = {
	'/': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, mainActivity, {
		appArea: 'aff-main-activity',
	}),
	'/activity-f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, femaleActivity, {
		appArea: 'aff-activity-female',
	}),
	'/activity-m': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, maleActivity, {
		appArea: 'aff-activity-male',
	}),
	'/pilates-f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, pilatesFemale, {
		appArea: 'aff-activity-female-pilates',
	}),
	'/yoga': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, yogaMain, {
		appArea: 'aff-main-yoga',
	}),
	'/yoga-f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, yogaFemale, {
		appArea: 'aff-activity-female-yoga',
	}),
	'/yoga-m': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, yogaMale, {
		appArea: 'aff-activity-male-yoga',
	}),
	'/somatic-yoga': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, somaticYogaFemale, {
		appArea: 'aff-activity-female-somatic-yoga',
	}),
	'/walking': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, walkingMain, {
		appArea: 'aff-main-walking',
	}),
	'/walking-f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, walkingFemale, {
		appArea: 'aff-activity-female-walking',
	}),
	'/walking-m': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, walkingMale, {
		appArea: 'aff-activity-male-walking',
	}),
}

export default {
	'/': mainActivity,
	'/activity-f': femaleActivity,
	'/activity-m': maleActivity,
	'/pilates-f': pilatesFemale,
	'/yoga': yogaMain,
	'/yoga-f': yogaFemale,
	'/yoga-m': yogaMale,
	'/somatic-yoga': somaticYogaFemale,
	'/walking': walkingMain,
	'/walking-f': walkingFemale,
	'/walking-m': walkingMale,
} as DomainConfig
