import config from '@/config'
import axios from 'axios'

import { paymentApiAxiosInstance as paymentApi } from '@/helpers/request'
import { os } from '@/helpers/device.js'
import { ANALYTIC_AGE_TO_AGE_RANGE_MAP } from '@/helpers/constants'

const preferredPaymentMethodsMap = {
	ApplePay: {
		defaultPaymentTab: 'stripePaymentRequestTab',
		tabsOrder: ['paymentRequest', 'paypal', 'card'],
	},
	PayPal: {
		defaultPaymentTab: 'braintreePaypalTab',
		tabsOrder: ['paypal', 'paymentRequest', 'card'],
	},
	CreditCard: {
		defaultPaymentTab: 'stripeCardTab',
		tabsOrder: ['card', 'paymentRequest', 'paypal'],
	},
}

export default {
	namespaced: true,

	state: {
		purchasedProduct: null,
		upgrades: {
			original: null,
			discounted: null,
		},
		promocode: null,
		removeDiscountAfterCountdown: false,
		overridenTabsOrder: null,
		overridenDefaultPaymentTab: null,
	},

	mutations: {
		setPurchasedProduct(state, product) {
			state.purchasedProduct = product
		},

		setUpgrades(state, upgrades) {
			state.upgrades.original = upgrades?.original
			state.upgrades.discounted = upgrades?.discounted
		},

		setPromocode(state, promocode) {
			state.promocode = promocode
		},

		setRemoveDiscountAfterCountdown(state, status) {
			state.removeDiscountAfterCountdown = status
		},

		setOverridenTabs(state, preferredPaymentMethod) {
			if (!preferredPaymentMethodsMap[preferredPaymentMethod]) {
				return
			}

			state.overridenTabsOrder = preferredPaymentMethodsMap[preferredPaymentMethod].tabsOrder
			state.overridenDefaultPaymentTab = preferredPaymentMethodsMap[preferredPaymentMethod].defaultPaymentTab
		},
	},

	getters: {
		getPromocode(state) {
			return state.promocode
		},
	},

	actions: {
		async setPromocodeByName({ commit }, promocode) {
			try {
				const { data } = await axios.get(`${config('SimpleAPIUrl')}v1/api/marketing/promocode`, {
					params: {
						promocode,
					},
				})

				commit('setPromocode', data)
			} catch (e) {
				commit('setPromocode', null)
				throw e
			}
		},

		async detectPreferredPaymentMethod(store) {
			const countryCodeUppercase = store.rootGetters.getCountryCodeUppercase
			const sex = store.rootGetters['survey/userSex']
			const utmSource = store.rootGetters['location/getQueryParam']('utm_source')
			let age = store.rootGetters['survey/userAge']

			if (age > 60) {
				age = 60
			}

			const ageRange = ANALYTIC_AGE_TO_AGE_RANGE_MAP[age]
			let media = 'other'

			if (utmSource === 'google') {
				media = 'google'
			} else if (utmSource === 'FB') {
				media = 'facebook'
			}

			const payload = {
				platform: os, // Android | iOS
				country: countryCodeUppercase, // US | CA | GB | AU | NZ ...
				ageRange: ageRange, // '18-29' | '30-39' | '40-49' | '50-59' | '60+'
				sex: sex, // male | female
				media: media, // google | facebook | other
				funnelType: store.rootGetters['location/getAppAreaConfig'].funnel.slug, // activity-female-v2 | female | activity-female-pilates | activity-male-v2 | male | activity-male-walking ....
			}

			const data = await paymentApi.post('v1/api/preferred-payment-method', payload) // 'ApplePay' | 'PayPal' | 'CreditCard'
			const preferredPaymentMethod = data?.data?.preferred_payment_method

			if (preferredPaymentMethod) {
				store.commit('setOverridenTabs', preferredPaymentMethod)
				return preferredPaymentMethod
			}

			return null
		},
	},
}
