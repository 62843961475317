import type { PartialExperimentConfig } from '../@types'

export default {
	main: {
		funnel: {
			id: 'ob-main-back-to-fasting',
			locale: ['en'],
		},
	},
	female: {
		funnel: {
			id: 'ob-female-back-to-fasting',
			locale: ['en'],
		},
	},
	male: {
		funnel: {
			id: 'ob-male-back-to-fasting',
			locale: ['en'],
		},
	},
} as PartialExperimentConfig
