import { ENV_DEV_OR_STAGING } from '@/helpers/environment'
import {
	DOMAINS_PREFIXES,
	getLandingPathByPaywallRoute,
	getDefaultAppAreaConfigByHostname,
	defineFunnelByParams,
} from '@/funnels/funnels.ts'
import paywallRoutes from '@/modules/Paywall/paywallRoutes'

const DOMAINS = {
	'activity.simple-life-app.com': {
		ACTIVITY: true,
	},
	'your-health-coach.com': {
		HEALTH_COACH: true,
	},
	localhost: {
		LOCALHOST: true,
	},
	default: {
		DEFAULT: true,
	},
}

/**
 * Method for extracting data from `URLSearchParams` entries to plain object
 * Supports multiple params with the same name
 * @param {iterable} entries - iterable collection of tuples, i.e. `[['exp', 'foo'], ['exp', 'bar'], ['a', 'b']]`
 * @returns {object} - result object, i.e `{ exp: ['foo', 'bar'], a: 'b' }`
 */
function groupSearchParamsByKey(entries) {
	return [...entries].reduce((result, tuple) => {
		const [key, val] = tuple
		if (result.hasOwnProperty(key)) {
			if (Array.isArray(result[key])) {
				result[key] = [...result[key], val]
			} else {
				result[key] = [result[key], val]
			}
		} else {
			result[key] = val
		}

		return result
	}, {})
}

function getFunnelPrefixFromPath(path) {
	const index = DOMAINS_PREFIXES.findIndex(([, regexp]) => regexp.test(path))

	if (index !== -1) {
		return DOMAINS_PREFIXES[index][0]
	}
	return '/'
}

export default {
	namespaced: true,

	state: {
		host: window.location.hostname,
		queryParams: {},
		initialPath: '',
		paywallRoute: '',
		initialUtmParams: {},
		appAreaConfig: null,
		initialFunnelType: null,
	},

	mutations: {
		storeQueryParams(state) {
			const urlSearchParams = new URLSearchParams(window.location.search)
			const params = groupSearchParamsByKey(urlSearchParams.entries())
			const normalizedParams = {}

			for (let param in params) {
				let paramValue = params[param]

				if (paramValue === '' || paramValue === 'true') {
					paramValue = true
				} else if (paramValue === 'false') {
					paramValue = false
				}

				if (param === 'email' && paramValue) {
					paramValue = paramValue.trim().replaceAll(' ', '+')
				}

				normalizedParams[param] = paramValue
			}

			state.queryParams = {
				...state.queryParams,
				...normalizedParams,
			}
		},

		setAppAreaConfig(state, appAreaConfig) {
			state.appAreaConfig = appAreaConfig
		},

		storeInitialPath(state, value) {
			state.initialPath = value
		},

		setInitialFunnelType(state, value) {
			state.initialFunnelType = value
		},

		setPaywallRoute(state, value) {
			state.paywallRoute = value
		},

		storeInitialUtmParams(state, value) {
			state.initialUtmParams = value
		},

		removeQueryParam(state, key) {
			this._vm.$delete(state.queryParams, key)
		},
	},

	getters: {
		hostname: (state, getters) => {
			let hostname = state.host
			if (ENV_DEV_OR_STAGING) {
				hostname = getters.getQueryParam('host') ?? 'simple-life-app.com'
			}
			return hostname
		},

		domain: (state, getters) => {
			let host = state.host

			if (ENV_DEV_OR_STAGING) {
				host = getters.getQueryParam('host') ?? state.host
			}

			return DOMAINS[host] ?? DOMAINS.default
		},

		getQueryParam: (state) => (parameter) => state.queryParams[parameter],

		isPaidTraffic: (state, getters) => Boolean(getters.getQueryParam('utm_source')),

		isForbesAffiliateTraffic: (state, getters) =>
			['aff.simple-life-app.com'].includes(getters.hostname) && getters.getQueryParam('utm_campaign') === 'Forbes-MKTP',

		isCrmAutologin: (state, getters) =>
			Boolean(
				getters.getQueryParam('email') && getters.getQueryParam('user') && getters.getQueryParam('from') === 'email',
			),

		manageSubscriptionSource: (state) => state.queryParams.manage_subscription_source ?? 'web',

		/**
		 * @return {{web: boolean, android: boolean, ios: boolean}}
		 */
		accountPlatform: (state, getters) => ({
			ios: getters.manageSubscriptionSource === 'ios',
			android: getters.manageSubscriptionSource === 'android',
			web: getters.manageSubscriptionSource !== 'ios' && getters.manageSubscriptionSource !== 'android',
		}),

		isAppToWebToAppScenario: (state) => state.queryParams.login_source === 'android',

		getAppAreaConfig: (state, getters) => state.appAreaConfig || getDefaultAppAreaConfigByHostname(getters.hostname),

		getPaywallRoute: (state, getters, rootState, rootGetters) => {
			// FIXME: temporary solution for CRM experiment
			const planQueryParam = rootGetters['location/getQueryParam']('plan')

			if (planQueryParam && paywallRoutes.map((route) => route.name).includes(planQueryParam)) {
				return planQueryParam
			}

			return state.paywallRoute
		},

		getLandingPathByPaywallRoute: (state, getters) => (paywallRoute) => {
			return getLandingPathByPaywallRoute(getters.hostname, paywallRoute)
		},

		getInitialPath(state) {
			return state.initialPath
		},

		isEmptyInitialUtmParams: (state) => {
			return Object.keys(state.initialUtmParams).length === 0
		},

		getInitialFunnelType: (state) => state.initialFunnelType,
	},
	actions: {
		/*
			Method for checking if user came from organic traffic
			We are setting local flag to use it in strapi/in code to show changes based on it
			Its being set only once on app initialisation
			Organic utm sources and/or utm campaigns are defined in config builder
			https://simple-admin.fstr.app/config-builder/config/15
			As an example here is 5 cases we defined as "organic":
			https://simple-life-app.com/ is organic – w/o any utm sources
			https://simple-life-app.com/?utm_source=simple_landing – from simple.life main page
			https://simple-life-app.com/?utm_source=nb_blog - from simple.life blog
			https://simple-life-app.com/?utm_source=cta_blog - from simple.life blog
			https://simple-life-app.com/?utm_campaign=US_Simple_Search_Web_F_EP_Brand_220722 - from google search. In this case it can contain any utm_source
			Anything else is not considered "organic"
			https://simple-life-app.com/?utm_source=FB – is NOT organic, it's from ads on facebook
			https://simple-life-app.com/?utm_source=google – is NOT organic, it's from ads on google
			etc...
		 */
		defineOrganicTrafficFlag({ getters, commit, rootGetters }) {
			const utmSource = getters['getQueryParam']('utm_source')
			const utmCampaign = getters['getQueryParam']('utm_campaign')
			const manageSubscriptionSource = getters['getQueryParam']('manage_subscription_source')
			const organicUtmValues = rootGetters['coreFeatures/FEAT_ORGANIC_UTM_VALUES']
			let isOrganicUtmSource = false

			if (
				!utmSource ||
				organicUtmValues?.sources?.some((v) => utmSource?.toString().includes(v)) ||
				organicUtmValues?.campaigns?.some((v) => utmCampaign?.toString().includes(v))
			) {
				isOrganicUtmSource = true
			}

			if (manageSubscriptionSource) {
				isOrganicUtmSource = false
			}
			commit('userFlags/updateLocalFlags', { organicTrafficOnboarding: isOrganicUtmSource }, { root: true })
		},

		// Storing initial path for usage in funnel selection logic
		storeInitialPath({ commit, getters, rootGetters }, { force = false, path = window.location.pathname } = {}) {
			const prefix = getFunnelPrefixFromPath(path)
			// To prevent user from changing gender funnel to main unintentionally
			const changeGenderFunnelPrefix = getters.getInitialPath !== prefix && prefix !== '/'
			if (force || !getters.getInitialPath || changeGenderFunnelPrefix) {
				const appAreaConfig = defineFunnelByParams({
					appArea: null,
					initialPath: prefix,
					country: rootGetters.getCountryCode,
					domain: getters.hostname,
				})

				commit('setAppAreaConfig', appAreaConfig)
				commit('storeInitialPath', prefix)
			}
		},
	},
}
